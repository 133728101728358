import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { isAdmin, localstoreUtilites } from "$utils/persistenceData";
import { isAccessSystemEnabled } from "$utils/plugins";
import { CommonRouter } from "./CommonRouter";
import PrivateRouteWrapper from "../components/PrivateRoute/PrivateRouteWrapper";
import HandleMqttData from "$components/PrivateRoute/PrivateRouteWrapper/handleMqttData";
import { PrivateRoute } from "$components/PrivateRoute";
import { ClipLoader } from "react-spinners";
import { GLOBAL_COLOR } from "$utils/constants";
const UserPage = lazy(() => import("./User/RootUser"));
const UserSetting = lazy(() => import("./UserSetting"));
const ModifyUser = lazy(() => import("./User/ModifyUser"));
const Department = lazy(() => import("./Department"));
const UnregisteredDevices = lazy(() => import("./UnregisteredDevices"));
const Device = lazy(() => import("./Device/RootDevice"));
const EditDevice = lazy(() => import("./Device/EditDevice"));
const AnalysisPage = lazy(() => import("./AnalysisPage"));
const DownloadMobileApp = lazy(() => import("./DownloadMobileApp"));
const About = lazy(() => import("./About"));
const TimeAttendanceSettingPage = lazy(() =>
  import("./TimeAttendance/TimeAttendanceSettingPage")
);
const TimeAttendanceReport = lazy(() =>
  import("./TimeAttendance/TimeAttendanceReport")
);
const LeaveRequestPage = lazy(() =>
  import("./TimeAttendance/LeaveRequestPage")
);
const LeaveManagementPage = lazy(() =>
  import("./TimeAttendance/LeaveManagementPage")
);
const Notification = lazy(() => import("./Notification"));
const ErrorPage = lazy(() => import("./error-page"));
const SettingPage = lazy(() => import("./SettingPage"));
const SystemAccount = lazy(() => import("./SystemAccount"));
const Company = lazy(() => import("./Company"));
const RoleSetting = lazy(() => import("./RoleSetting"));
const AccessGroup = lazy(() => import("./AccessGroup"));
const ModifyElevator = lazy(() => import("./Elevator/ModifyElevator"));
const SystemLog = lazy(() => import("./SystemLog"));
const MessageSetting = lazy(() => import("./MessageSetting"));
const Building = lazy(() => import("./Building"));
const Holiday = lazy(() => import("./Holiday"));
const EventRecovery = lazy(() => import("./EventRecovery"));
const RootTimeZone = lazy(() => import("./TimeZone/RootTimeZone"));
const ModifyTimeZone = lazy(() => import("./TimeZone/ModifyTimeZone"));
const AccessibleDoor = lazy(() => import("./AccessibleDoor"));
const Profile = lazy(() => import("./Profile"));
const CarMonitroing = lazy(() => import("./CarMonitroing"));
const Monitoring = lazy(() => import("./Monitoring"));
const DeviceMonitoirng = lazy(() => import("./DeviceMonitoirng"));
const EventLog = lazy(() => import("./EventLog"));
const DeviceUpdate = lazy(() => import("./DeviceUpdate"));
const Account = lazy(() => import("./Account"));
const FirmwareVersion = lazy(() => import("./FirmwareVersion"));
const AdminDashboard = lazy(() => import("./Dashboard/AdminDashboard"));
const AccessDashboard = lazy(() => import("./Dashboard/AccessDashboard"));
const Elevator = lazy(() => import("./Elevator/RootElevator"));
const EmailTemplate = lazy(() => import("./EmailTemplate/RootEmailTemplate"));
const EditEmailTemplate = lazy(() =>
  import("./EmailTemplate/EditEmailTemplate")
);
const WorkingTime = lazy(() => import("./WorkingTime/RootWorkingTime"));
const ModifyWorkingTime = lazy(() => import("./WorkingTime/ModifyWorkingTime"));
const RootBook = lazy(() => import("./Book/RootBook"));
const BorrowBook = lazy(() => import("./Book/BorrowBook"));
const BookArea = lazy(() => import("./Book/BookArea"));
const BookReport = lazy(() => import("./Book/BookReport"));
const CheckUser = lazy(() => import("./CheckUser"));
const Meeting = lazy(() => import("./Meeting"));
const Visit = lazy(() => import("./Visit/RootVisit"));
const VisitReport = lazy(() => import("./VisitReport"));
const VisitSetting = lazy(() => import("./VisitSetting"));
const VisitCard = lazy(() => import("./CardIssuing/VisitCard"));
const AccessCard = lazy(() => import("./CardIssuing/AccessCard"));
const TempCard = lazy(() => import("./CardIssuing/TempCard"));
const CardReport = lazy(() => import("./CardIssuing/CardReport"));
const PrinterSetting = lazy(() => import("./CardIssuing/PrinterSetting"));
const CardLayout = lazy(() => import("./CardLayout/RootCardLayout"));
const ModifyCardLayout = lazy(() => import("./CardLayout/ModifyCardLayout"));

const PrivateRouter = ({ isAuthed, accountType, permission }) => {
  const refreshToken = localstoreUtilites.getRefreshTokenToLocalStorage();
  return (
    <>
      {/* handle mqtt for notification & progress */}
      <HandleMqttData />
      <BrowserRouter>
        <PrivateRouteWrapper
          accountType={accountType}
          isAuthed={isAuthed}
          refreshToken={refreshToken}
          permission={permission}
        >
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ClipLoader color={GLOBAL_COLOR.grayColor} />
              </div>
            }
          >
            <Routes>
              {CommonRouter()}
              {PrivateRoute({
                path: "/",
                element: isAdmin() ? (
                  <AdminDashboard />
                ) : isAccessSystemEnabled() ? (
                  <AccessDashboard />
                ) : (
                  // <CanteenDashboard />
                  <></>
                ),
              })}
              {PrivateRoute({
                path: "/download-mobile-app",
                element: <DownloadMobileApp />,
              })}
              {PrivateRoute({
                path: "/user",
                element: <UserPage />,
              })}
              {PrivateRoute({
                path: "/user/add",
                element: <ModifyUser />,
              })}
              {PrivateRoute({
                path: "/user/edit/:id",
                element: <ModifyUser />,
              })}
              {PrivateRoute({
                path: "/department",
                element: <Department />,
              })}
              {PrivateRoute({
                path: "/unregistered-devices",
                element: <UnregisteredDevices />,
              })}
              {PrivateRoute({
                path: "/device-setting",
                element: <Device />,
              })}
              {PrivateRoute({
                path: "/device-setting/add",
                element: <EditDevice />,
              })}
              {PrivateRoute({
                path: "/device-setting/edit/:id",
                element: <EditDevice />,
              })}
              {PrivateRoute({
                path: "/message-setting",
                element: <MessageSetting />,
              })}
              {PrivateRoute({
                path: "/building",
                element: <Building />,
              })}
              {PrivateRoute({
                path: "/monitoring",
                element: isAdmin() ? <DeviceMonitoirng /> : <Monitoring />,
              })}
              {PrivateRoute({
                path: "/access-group",
                element: <AccessGroup />,
              })}
              {PrivateRoute({
                path: "/access-times",
                element: <RootTimeZone />,
              })}
              {PrivateRoute({
                path: "/access-times/add",
                element: <ModifyTimeZone />,
              })}
              {PrivateRoute({
                path: "/access-times/edit/:id",
                element: <ModifyTimeZone />,
              })}
              {PrivateRoute({
                path: "/holiday",
                element: <Holiday />,
              })}
              {PrivateRoute({
                path: "/account-management",
                element: <Account />,
              })}
              {PrivateRoute({
                path: "/event",
                element: <EventLog />,
              })}
              {PrivateRoute({
                path: "/device-monitoring",
                element: <DeviceMonitoirng />,
              })}
              {PrivateRoute({
                path: "/system-log",
                element: <SystemLog />,
              })}
              {PrivateRoute({
                path: "/accessible-door",
                element: <AccessibleDoor />,
              })}
              {PrivateRoute({
                path: "/analysis",
                element: <AnalysisPage />,
              })}
              {PrivateRoute({
                path: "/setting",
                element: <SettingPage />,
              })}
              {PrivateRoute({
                path: "/check-user",
                element: <CheckUser />,
              })}
              {/*             {PrivateRoute({
              path: "/check-device",
              element: <CheckDeviceSetting />,
            })}
             */}
              {PrivateRoute({
                path: "/device-update",
                element: <DeviceUpdate />,
              })}
              {PrivateRoute({
                path: "/firmware-version",
                element: <FirmwareVersion />,
              })}
              {PrivateRoute({
                path: "/event-recovery",
                element: <EventRecovery />,
              })}
              {PrivateRoute({
                path: "/visit",
                element: <Visit />,
              })}
              {PrivateRoute({
                path: "/visit/report",
                element: <VisitReport />,
              })}
              {/* 
            {PrivateRoute({
              path: "/visit/camera",
              element: <AddVisitByCamera />,
            })}
            {PrivateRoute({
              path: "/visit/apply",
              element: <EditVisit />,
            })}
            {PrivateRoute({
              path: "/visit/apply/:id",
              element: <EditVisit />,
            })}
            {PrivateRoute({
              path: "/visit/in-advance",
              element: <EditVisit />,
              ignoreLogin: true,
            })}
            {PrivateRoute({
              path: "/visit/edit/:id",
              element: <EditVisit />,
            })}
 */}
              {PrivateRoute({
                path: "/visit/settings",
                element: <VisitSetting />,
              })}
              {PrivateRoute({
                path: "/company",
                element: <Company />,
              })}
              {PrivateRoute({
                path: "/about",
                element: <About />,
              })}
              {PrivateRoute({
                path: "/workingtime-type",
                element: <WorkingTime />,
              })}
              {PrivateRoute({
                path: "/workingtime-type/add",
                element: <ModifyWorkingTime />,
              })}
              {PrivateRoute({
                path: "/workingtime-type/edit/:id",
                element: <ModifyWorkingTime />,
              })}
              {PrivateRoute({
                path: "/role-setting",
                element: <RoleSetting />,
              })}
              {PrivateRoute({
                path: "/notifications",
                element: <Notification />,
              })}
              {PrivateRoute({
                path: "/profile",
                element: <Profile />,
              })}
              {PrivateRoute({
                path: "/system-account",
                element: <SystemAccount />,
              })}
              {PrivateRoute({
                path: "/email-templates",
                element: <EmailTemplate />,
              })}
              {PrivateRoute({
                path: "/email-templates/edit/:id",
                element: <EditEmailTemplate />,
              })}
              {/* {PrivateRoute({
              path: "/cameras",
              element: <CameraPage />,
            })} */}
              {PrivateRoute({
                path: "/meetings",
                element: <Meeting />,
              })}
              {PrivateRoute({
                path: "/car-monitoring",
                element: <CarMonitroing />,
              })}
              {/*             {PrivateRoute({
              path: "/car-report",
              element: <CarReportPage />,
            })} */}
              {PrivateRoute({
                path: "/elevators",
                element: <Elevator />,
              })}
              {PrivateRoute({
                path: "/elevators/add",
                element: <ModifyElevator />,
              })}
              {PrivateRoute({
                path: "/elevators/edit/:id",
                element: <ModifyElevator />,
              })}
              {PrivateRoute({
                path: "/issuing/visit-card",
                element: <VisitCard />,
              })}
              {PrivateRoute({
                path: "/issuing/access-card",
                element: <AccessCard />,
              })}
              {PrivateRoute({
                path: "/issuing/temp-card",
                element: <TempCard />,
              })}
              {PrivateRoute({
                path: "/issuing/report",
                element: <CardReport />,
              })}
              {PrivateRoute({
                path: "/issuing/setting",
                element: <PrinterSetting />,
              })}
              {PrivateRoute({
                path: "/card-layouts",
                element: <CardLayout />,
              })}
              {PrivateRoute({
                path: "/card-layouts/add",
                element: <ModifyCardLayout />,
              })}
              {PrivateRoute({
                path: "/card-layouts/edit/:id",
                element: <ModifyCardLayout />,
              })}
              {/*  {PrivateRoute({
                path="/person",
                element: <Person />
              })} */}
              {PrivateRoute({
                path: "/user/settings",
                element: <UserSetting />,
              })}
              {PrivateRoute({
                path: "/book",
                element: <RootBook />,
              })}
              {PrivateRoute({
                path: "/book-borrow",
                element: <BorrowBook />,
              })}
              {PrivateRoute({
                path: "/book-area",
                element: <BookArea />,
              })}
              {PrivateRoute({
                path: "/book-report",
                element: <BookReport />,
              })}
              {PrivateRoute({
                path: "/time-attendance-report",
                element: <TimeAttendanceReport />,
              })}
              {PrivateRoute({
                path: "/leave-request",
                element: <LeaveRequestPage />,
              })}
              {PrivateRoute({
                path: "/leave-management",
                element: <LeaveManagementPage />,
              })}
              {PrivateRoute({
                path: "/time-attendance-setting",
                element: <TimeAttendanceSettingPage />,
              })}
              <Route exact path="/*" element={<ErrorPage />} />
            </Routes>
          </Suspense>
        </PrivateRouteWrapper>
      </BrowserRouter>
    </>
  );
};
export default PrivateRouter;
