import { GLOBAL_COLOR } from "$utils/constants";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { styled } from "styled-components";

export const ButtonComponent = ({
  text,
  onClick,
  disabled,
  contained = true,
  icon,
  circle = false,
  width = circle ? "35px" : "100%",
  fontSize = contained ? "15px" : "12px",
  height = circle ? "35px" : contained ? "45px" : "30px",
  shadow = true,
  // text styles
  justify = "center",
  gap = "10px",
  textUnderline = false,
  bgcolor,
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <StyledButton
      {...rest}
      disabled={disabled}
      circle={circle}
      onClick={!disabled ? onClick : undefined}
      width={width}
      height={height}
      shadow={
        shadow
          ? "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
          : "none"
      }
      fontSize={fontSize}
      contained={contained ? contained.toString() : null}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      onHoverStart={() => {
        if (!disabled) {
          setIsHovered(true);
        }
      }}
      onHoverEnd={() => setIsHovered(false)}
      whileTap={
        !disabled ? { border: `3px solid ${GLOBAL_COLOR.orangeColor}` } : {}
      }
      transition={{ border: { duration: 0.05 } }}
      isfocus={isFocus ? isFocus.toString() : null}
      animate={{
        backgroundColor: isHovered
          ? bgcolor
            ? bgcolor
            : contained
            ? GLOBAL_COLOR.darkGrayColor
            : "rgba(0,0,0,0.1)"
          : bgcolor
          ? bgcolor
          : contained
          ? GLOBAL_COLOR.grayColor
          : "inherit",
      }}
    >
      <ButtonText gap={gap} justify={justify}>
        {icon && icon}
        {text && (
          <span style={textUnderline ? { textDecoration: "underline" } : {}}>
            {text}
          </span>
        )}
      </ButtonText>
    </StyledButton>
  );
};
const StyledButton = styled(motion.button)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: ${(props) => props.fontSize};
  color: ${(props) =>
    props.contained ? props.theme.lightGrayColor : props.theme.grayColor};
  font-family: "NotoSans";
  border-radius: ${(props) => (props.circle ? "50%" : "3px")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  box-shadow: ${(props) => (props.contained ? props.shadow : "")};
  position: relative;
  outline: none;
  border: none;
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;
const ButtonText = styled.span`
  display: flex;
  gap: ${(props) => props.gap};
  justify-content: ${(props) => props.justify};
  align-items: center;
`;
