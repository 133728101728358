import { memo } from "react";
import { SelectComponent } from "$components/SelectComponent";
import { FormattedMessage } from "react-intl";

const SelectRowsPerPage = ({
  isSecondTable,
  isThirdTable,
  rowLength,
  handleChangeRowsPerPage,
  messages,
  isLoading,
  isAssignUI,
}) => {
  const options = [5, 10, 25, 50, 100, rowLength];
  return (
    <SelectComponent
      id={`rowsPerPage${isSecondTable}${isThirdTable}${isAssignUI}`}
      label={<FormattedMessage {...messages.rowsPerPage} />}
      width="70px"
      options={[...new Set(options)]
        .sort((a, b) => a - b)
        .map((op) => ({ id: op, name: op }))}
      value={rowLength}
      onChange={handleChangeRowsPerPage}
      disabled={isLoading}
    />
  );
};
export default memo(SelectRowsPerPage);
