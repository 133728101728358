import React, { useEffect, useState } from "react";
import {
  typeOfApiState,
  ButtonRowWidthState,
  PageModalState,
  TooltipTextState,
  fixedMenuState,
  loadingState,
  menuOverlayState,
  mouseLocaleState,
  subSelectedRowState,
  progressBtnModalState,
} from "$utils/atom";
import { useResetRecoilState } from "recoil";

export const ResetDataComponent = ({ children, pathname }) => {
  const [ready, setReady] = useState(false);
  const resetsubSelectedRowState = useResetRecoilState(subSelectedRowState);
  const resetLoadingState = useResetRecoilState(loadingState);
  const resetmenuOverlayState = useResetRecoilState(menuOverlayState);
  const resetmouseLocaleState = useResetRecoilState(mouseLocaleState);
  const resetTooltipTextState = useResetRecoilState(TooltipTextState);
  const resetPageModalState = useResetRecoilState(PageModalState);
  const resetButtonRowWidth = useResetRecoilState(ButtonRowWidthState);
  const resetIsFixedMenu = useResetRecoilState(fixedMenuState);
  const resetTypeOfApiState = useResetRecoilState(typeOfApiState);
  const resetProgressModal = useResetRecoilState(progressBtnModalState);
  useEffect(() => {
    setReady(true);
    return () => {
      resetsubSelectedRowState();
      resetLoadingState();
      resetmenuOverlayState();
      resetmouseLocaleState();
      resetTooltipTextState();
      resetPageModalState();
      resetTypeOfApiState();
      resetButtonRowWidth();
      resetIsFixedMenu();
      resetProgressModal();
    };
  }, [pathname]);
  return <>{ready && children}</>;
};
