import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Time } from "../Time";
import { GetSubDisplayEventInfo } from "../api";
import { deviceMonitoringMessages } from "routes/DeviceMonitoirng/messages";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faQuestion,
  faUser,
  faUsers,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { TableComponent } from "$components/TableComponent";
import {
  NORMAL_TYPES,
  convertDataDisplayMonitoring,
  convertVisitorDisplayMonitoring,
  headers,
  headersVisitor,
} from "../utils";
import { GLOBAL_COLOR } from "$utils/constants";
import { format } from "date-fns";
import { cardIcon } from "routes/Monitoring/utils";
import {
  EachContentBox,
  EachEventDataBox,
  EachEventImg,
  EachEventText,
  EachEventTextBox,
  EachGrid,
  GridBox,
  Header,
  NoContentBox,
} from "../styles";
import { HandleEventDataMqtt } from "../handleEventDataMqtt";
import { withSubMonitoring } from "../withSubMonitoring";

export const SubMonitoringTwo = withSubMonitoring(
  ({
    token,
    jsonData,
    enableDisplayListVisitor,
    enableDisplayAbnormalEvent,
    enableDisplayCanteenEvent,
  }) => {
    const { data } = GetSubDisplayEventInfo(token);
    const [visitData, setVisitData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [eventUsers, setEventUsers] = useState(0);
    const [eventVisitors, setEventVisitors] = useState(0);
    const [eventAbnormal, setEventAbnormal] = useState(0);
    const [eventCanteen, setEventCanteen] = useState(0);
    useEffect(() => {
      if (data) {
        setEventUsers(data?.eventUsers);
        setEventVisitors(data?.eventVisitors);
        setEventAbnormal(data?.eventAbnormal);
        setEventCanteen(data?.eventCanteen);
        if (enableDisplayListVisitor) {
          setVisitData(
            convertVisitorDisplayMonitoring(
              data.visitors?.data,
              data.visitorStatusTypes
            )
          );
        }
        setEventData(
          convertDataDisplayMonitoring(data.eventLogs?.data, data.eventTypes)
        );
      }
    }, [data]);
    return (
      <SubMonitoringTwoBox>
        {data && (
          <>
            {eventData && (
              <HandleEventDataMqtt
                jsonData={jsonData}
                eventData={eventData}
                setEventData={setEventData}
                setVisitData={setVisitData}
                typeDetails={data.eventTypes}
                typeStatusDetails={data.visitorStatusTypes}
                setEventAbnormal={setEventAbnormal}
                setEventCanteen={setEventCanteen}
                setEventVisitors={setEventVisitors}
                setEventUsers={setEventUsers}
              />
            )}
            <Header>
              <GridBox>
                <EachGrid
                  style={{
                    ...(!enableDisplayListVisitor && {
                      border: `3px solid ${GLOBAL_COLOR.grayColor}`,
                      backgroundColor: GLOBAL_COLOR.lightGrayColor,
                      color: GLOBAL_COLOR.grayColor,
                    }),
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                  <FormattedMessage {...deviceMonitoringMessages.eventUser} />
                  {eventUsers}
                </EachGrid>
                <EachGrid
                  style={{
                    ...(enableDisplayListVisitor && {
                      border: `3px solid ${GLOBAL_COLOR.grayColor}`,
                      backgroundColor: GLOBAL_COLOR.lightGrayColor,
                      color: GLOBAL_COLOR.grayColor,
                    }),
                  }}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <FormattedMessage
                    {...deviceMonitoringMessages.eventVisitor}
                  />
                  {eventVisitors}
                </EachGrid>
                {enableDisplayAbnormalEvent && (
                  <EachGrid>
                    <FontAwesomeIcon icon={faQuestion} />
                    <FormattedMessage
                      {...deviceMonitoringMessages.eventAbnormal}
                    />
                    {eventAbnormal}
                  </EachGrid>
                )}
                {enableDisplayCanteenEvent && (
                  <EachGrid>
                    <FontAwesomeIcon icon={faUtensils} />
                    <FormattedMessage
                      {...deviceMonitoringMessages.eventCanteen}
                    />
                    {eventCanteen}
                  </EachGrid>
                )}
              </GridBox>
              <Time />
            </Header>
            <FlexBox>
              <TableBox>
                {enableDisplayListVisitor ? (
                  <TableComponent
                    tableId="ScreenMonitoringV2VisitorPage"
                    data={visitData}
                    headers={headersVisitor}
                    totalCount={data.visitors?.meta.recordsFiltered}
                    checkBox={false}
                    noPagination
                  />
                ) : (
                  <TableComponent
                    tableId="ScreenMonitoringV2Page"
                    data={eventData}
                    headers={headers}
                    totalCount={data.eventLogs?.meta.recordsFiltered}
                    checkBox={false}
                    noPagination
                  />
                )}
              </TableBox>
              <RightBox>
                {eventData?.length > 0 ? (
                  <RightContent>
                    {eventData.map((item) => (
                      <EachContentBox key={item.id}>
                        <div
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "10px",
                          }}
                        >
                          {cardIcon[item.cardTypeId]}
                        </div>
                        <EachEventImg
                          src={item.avatar || "/images/avatar-male-default.jpg"}
                        />
                        <EachEventDataBox>
                          <EachEventTextBox>
                            <EachEventText>
                              <FormattedMessage
                                {...deviceMonitoringMessages.headerName}
                              />
                            </EachEventText>
                            <span>{item.userName}</span>
                          </EachEventTextBox>
                          <EachEventTextBox>
                            <EachEventText>
                              <FormattedMessage
                                {...deviceMonitoringMessages.headerDepartment}
                              />
                            </EachEventText>
                            <span>{item.department}</span>
                          </EachEventTextBox>
                          <EachEventTextBox>
                            <EachEventText>
                              <FormattedMessage
                                {...deviceMonitoringMessages.headerBodyTemperature}
                              />
                            </EachEventText>
                            <span>{item.bodyTemperature}</span>
                          </EachEventTextBox>
                          <EachEventTextBox>
                            <EachEventText>
                              <FormattedMessage
                                {...deviceMonitoringMessages.headerEventDetail}
                              />
                            </EachEventText>
                            <span
                              style={{
                                color:
                                  NORMAL_TYPES.includes(item.eventType) ||
                                  NORMAL_TYPES.includes(item.eventDetailCode)
                                    ? GLOBAL_COLOR.greenColor
                                    : GLOBAL_COLOR.redColor,
                              }}
                            >
                              {item.eventDetail}
                            </span>
                          </EachEventTextBox>
                          <EachEventTextBox>
                            <EachEventText>
                              <FormattedMessage
                                {...deviceMonitoringMessages.headerAccessTime}
                              />
                            </EachEventText>
                            <span>
                              {format(
                                new Date(parseInt(item.unixTime, 10) * 1000),
                                "HH:mm:ss"
                              )}
                            </span>
                          </EachEventTextBox>
                        </EachEventDataBox>
                      </EachContentBox>
                    ))}
                  </RightContent>
                ) : (
                  <NoContentBox>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    <span>No Content</span>
                  </NoContentBox>
                )}
              </RightBox>
            </FlexBox>
          </>
        )}
      </SubMonitoringTwoBox>
    );
  }
);
const SubMonitoringTwoBox = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-color: ${(props) => props.theme.lightGrayColor};
  color: ${(props) => props.theme.grayColor};
`;
const FlexBox = styled.div`
  display: flex;
  height: 100%;
  gap: 20px;
`;
const TableBox = styled.div`
  width: calc(100% - 380px);
  height: calc(100% - 60px);
  flex-shrink: 1;
`;
const RightBox = styled.div`
  width: 360px;
  flex-shrink: 0;
  height: calc(100% - 100px);
  padding-top: 40px;
`;
const RightContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
