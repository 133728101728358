import {
  assignSelectedRowState,
  assignTableFilterState,
  assignTablePageState,
  assignTableRowsPerPageState,
  assignTableSortDirectState,
  assignTableSortState,
  progressBtnModalState,
  progressState,
  selectedRowState,
  subSelectedRowState,
  subTableFilterState,
  subTablePageState,
  subTableRowsPerPageState,
  subTableSortDirectState,
  subTableSortState,
  tableFilterState,
  tablePageState,
  tableRowsPerPageState,
  tableSortDirectState,
  tableSortState,
  thirdSelectedRowState,
  thirdTableFilterState,
  thirdTablePageState,
  thirdTableRowsPerPageState,
  thirdTableSortDirectState,
  thirdTableSortState,
  updatedRowState,
} from "$utils/atom";
import React, { memo, useEffect } from "react";
import { useResetRecoilState } from "recoil";

export const HandleResetWhenUnmount = memo(
  ({ isSecondTable, isThirdTable, isAssignUI, noPagination }) => {
    const resetselectedRowState = useResetRecoilState(
      isAssignUI
        ? assignSelectedRowState
        : isThirdTable
        ? thirdSelectedRowState
        : !isSecondTable
        ? selectedRowState
        : subSelectedRowState
    );
    const resettablePageState = useResetRecoilState(
      isAssignUI
        ? assignTablePageState
        : isThirdTable
        ? thirdTablePageState
        : !isSecondTable
        ? tablePageState
        : subTablePageState
    );
    const resettableRowsPerPageState = useResetRecoilState(
      isAssignUI
        ? assignTableRowsPerPageState
        : isThirdTable
        ? thirdTableRowsPerPageState
        : !isSecondTable
        ? tableRowsPerPageState
        : subTableRowsPerPageState
    );
    const resettableSortState = useResetRecoilState(
      isAssignUI
        ? assignTableSortState
        : isThirdTable
        ? thirdTableSortState
        : !isSecondTable
        ? tableSortState
        : subTableSortState
    );
    const resettableSortDirectState = useResetRecoilState(
      isAssignUI
        ? assignTableSortDirectState
        : isThirdTable
        ? thirdTableSortDirectState
        : !isSecondTable
        ? tableSortDirectState
        : subTableSortDirectState
    );
    const resettableFilterState = useResetRecoilState(
      isAssignUI
        ? assignTableFilterState
        : isThirdTable
        ? thirdTableFilterState
        : !isSecondTable
        ? tableFilterState
        : subTableFilterState
    );
    const resetprogressState = useResetRecoilState(progressState);
    const resetprogressBtnModalState = useResetRecoilState(
      progressBtnModalState
    );
    const resetupdatedRowState = useResetRecoilState(updatedRowState);
    useEffect(() => {
      return () => {
        if (!isSecondTable && !isThirdTable && !isAssignUI) {
          resetprogressState();
          resetprogressBtnModalState();
        }
        if (!noPagination) {
          resettablePageState();
          resettableRowsPerPageState();
          resettableSortState();
          resettableSortDirectState();
          resettableFilterState();
        }
        resetselectedRowState();
        resetupdatedRowState();
      };
    });
    return <></>;
  }
);
