import {
  assignSelectedRowState,
  assignTableFilterState,
  assignTablePageState,
  selectedRowState,
  subSelectedRowState,
  subTableFilterState,
  subTablePageState,
  tableFilterState,
  tablePageState,
  thirdSelectedRowState,
  thirdTableFilterState,
  thirdTablePageState,
} from "$utils/atom";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useDidMountEffect } from "$utils/hooks/useDidMountEffect";

export const HandleResetWhenSearch = ({
  isThirdTable,
  isSecondTable,
  isAssignUI,
  keepSaveSelectedEvenChangeMeta,
}) => {
  // 사용자가 검색기능을 사용하면 selected와 page를 초기화
  const filter = useRecoilValue(
    isAssignUI
      ? assignTableFilterState
      : isThirdTable
      ? thirdTableFilterState
      : isSecondTable
      ? subTableFilterState
      : tableFilterState
  );
  const setSelected = useSetRecoilState(
    isAssignUI
      ? assignSelectedRowState
      : isThirdTable
      ? thirdSelectedRowState
      : isSecondTable
      ? subSelectedRowState
      : selectedRowState
  );
  const setPage = useSetRecoilState(
    isAssignUI
      ? assignTablePageState
      : isThirdTable
      ? thirdTablePageState
      : isSecondTable
      ? subTablePageState
      : tablePageState
  );
  useDidMountEffect(() => {
    if (!keepSaveSelectedEvenChangeMeta) {
      setSelected([]);
    }
    setPage(1);
  }, [filter]);
  return <></>;
};
