import React, { memo, useEffect } from "react";
import { FlexBox, IconBox } from "./style";
import { SelectComponent } from "$components/SelectComponent";
import { FormattedMessage } from "react-intl";
import { useRecoilState } from "recoil";
import {
  assignTableSortDirectState,
  assignTableSortState,
  subTableSortDirectState,
  subTableSortState,
  tableSortDirectState,
  tableSortState,
  thirdTableSortDirectState,
  thirdTableSortState,
} from "$utils/atom";
import { ButtonComponent } from "$components/ButtonComponent";
import { ToolTipComponent } from "$components/ToolTipComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons";

const SortComponent = ({
  sortingItem,
  messages,
  isLoading,
  setSelected,
  isSecondTable,
  isThirdTable,
  isAssignUI,
}) => {
  const [tableSort, setTableSort] = useRecoilState(
    isAssignUI
      ? assignTableSortState
      : isSecondTable
      ? subTableSortState
      : isThirdTable
      ? thirdTableSortState
      : tableSortState
  );
  const [tableSortDirect, setTableSortDirect] = useRecoilState(
    isAssignUI
      ? assignTableSortDirectState
      : isSecondTable
      ? subTableSortDirectState
      : isThirdTable
      ? thirdTableSortDirectState
      : tableSortDirectState
  );
  const onChangeSortDirection = () => {
    const value = tableSortDirect === "asc" ? "desc" : "asc";
    setTableSortDirect(value);
  };
  // reset selected
  useEffect(() => {
    setSelected([]);
  }, [tableSort, tableSortDirect]);
  return (
    <FlexBox>
      <SelectComponent
        id={`changeSort${isSecondTable}${isThirdTable}${isAssignUI}`}
        label={<FormattedMessage {...messages.changeSort} />}
        width="150px"
        options={sortingItem}
        value={tableSort}
        onChange={setTableSort}
        disabled={isLoading}
      />
      <ToolTipComponent
        text={
          tableSortDirect === "asc" ? (
            <FormattedMessage {...messages.ascending} />
          ) : (
            <FormattedMessage {...messages.descending} />
          )
        }
      >
        <ButtonComponent
          icon={
            <IconBox
              style={
                tableSortDirect === "asc"
                  ? { transform: "rotateX(180deg)" }
                  : {}
              }
            >
              <FontAwesomeIcon icon={faSortAmountDown} />
            </IconBox>
          }
          onClick={onChangeSortDirection}
          disabled={isLoading}
          contained={false}
          width="25px"
          height="25px"
          circle
        />
      </ToolTipComponent>
    </FlexBox>
  );
};
export default memo(SortComponent);
