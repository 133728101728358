import React from "react";
import { styled } from "styled-components";

export const AvatarComponent = ({ src, alt = "avatar", size = "30px" }) => {
  return <ImgBox size={size} src={src} />;
};

const ImgBox = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  overflow: hidden;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;
