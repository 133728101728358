import { defineMessages } from "react-intl";

const scope = "demasterpro.containers.App";

export const privateRouteMessages = defineMessages({
  elevators: {
    id: `${scope}.elevators`,
    defaultMessage: `Elevators`,
  },
  viewAllNotification: {
    id: `demasterpro.components.Notification.viewAllNotification`,
    defaultMessage: `모든 알림 보기`,
  },
  secondsAgo: {
    id: `demasterpro.components.Notification.secondsAgo`,
    defaultMessage: ` 초 전`,
  },
  minutesAgo: {
    id: `demasterpro.components.Notification.minutesAgo`,
    defaultMessage: ` 분 전`,
  },
  hoursAgo: {
    id: `demasterpro.components.Notification.hoursAgo`,
    defaultMessage: ` 시간 전`,
  },
  daysAgo: {
    id: `demasterpro.components.Notification.daysAgo`,
    defaultMessage: ` 일 전`,
  },
  markAllAsRead: {
    id: `demasterpro.components.Notification.markAllAsRead`,
    defaultMessage: `전체 읽음으로 표시`,
  },
  clearAllRead: {
    id: `demasterpro.components.Notification.clearAllRead`,
    defaultMessage: `읽은 항목들 초기화`,
  },
  NotAccess: {
    id: `demasterpro.App.NotAccess`,
    defaultMessage: `NotAccess`,
  },
  NowLocation: {
    id: `demasterpro.App.NowLocation`,
    defaultMessage: `현재 위치`,
  },
  goToProfile: {
    id: `demasterpro.App.goToProfile`,
    defaultMessage: `See Profile`,
  },
  logout: {
    id: `demasterpro.App.logout`,
    defaultMessage: `Log Out`,
  },
  menu: {
    id: `demasterpro.App.menu`,
    defaultMessage: `Menu`,
  },
  titleAddVisitRoute: {
    id: `${scope}.titleAddVisitRoute`,
    defaultMessage: "Visitor Add/Edit",
  },
  titleDeviceUpdateRoute: {
    id: `${scope}.titleDeviceUpdateRoute`,
    defaultMessage: "Device Update",
  },
  titleEventRecoveryRoute: {
    id: `${scope}.titleEventRecoveryRoute`,
    defaultMessage: "Event Recovery",
  },
  titleTransmitDataRoute: {
    id: `${scope}.titleTransmitDataRoute`,
    defaultMessage: "Transmit Data",
  },
  titleAboutVersionRoute: {
    id: `${scope}.titleAboutVersionRoute`,
    defaultMessage: "About",
  },
  titleWorkingTimeTypeRoute: {
    id: `${scope}.titleWorkingTimeTypeRoute`,
    defaultMessage: "Working Time Type",
  },
  titleMessageLogRoute: {
    id: `${scope}.titleMessageLogRoute`,
    defaultMessage: "Message Log",
  },
  titleNotFoundRoute: {
    id: `${scope}.titleNotFoundRoute`,
    defaultMessage: "Page Not Found",
  },
  titleEmergencyRoute: {
    id: `${scope}.titleEmergencyRoute`,
    defaultMessage: "Emergency",
  },
  titleCheckUserRoute: {
    id: `${scope}.titleCheckUserRoute`,
    defaultMessage: "Check User Information",
  },
  titleSettingRoute: {
    id: `${scope}.titleSettingRoute`,
    defaultMessage: "Setting",
  },
  titlecheckDeviceRoute: {
    id: `${scope}.titleCheckDeviceRoute`,
    defaultMessage: "Check Device Setting",
  },
  titleRegisteredUser: {
    id: `${scope}.titleRegisteredUser`,
    defaultMessage: "Registered User",
  },
  titleAnalysis: {
    id: `${scope}.titleAnalysis`,
    defaultMessage: "Analysis",
  },
  titleAccessibleDoor: {
    id: `${scope}.titleAccessibleDoor`,
    defaultMessage: "Accessible Door",
  },
  titleSystemLogRoute: {
    id: `${scope}.titleSystemLogRoute`,
    defaultMessage: "System Log",
  },
  titleDeviceMonitorRoute: {
    id: `${scope}.titleDeviceMonitorRoute`,
    defaultMessage: "Device Monitoring",
  },
  titleDeviceControlRoute: {
    id: `${scope}.titleDeviceControlRoute`,
    defaultMessage: "Device Control",
  },
  titleEventLogRoute: {
    id: `${scope}.titleEventLogRoute`,
    defaultMessage: "Event Logs",
  },
  titleAccountManagementRoute: {
    id: `${scope}.titleAccountManagementRoute`,
    defaultMessage: "Account Management",
  },
  titleHolidayRoute: {
    id: `${scope}.titleHolidayRoute`,
    defaultMessage: "Holiday",
  },
  titleEditTimezoneRoute: {
    id: `${scope}.titleEditTimezoneRoute`,
    defaultMessage: "Edit timezone",
  },
  titleAddTimezoneRoute: {
    id: `${scope}.titleAddTimezoneRoute`,
    defaultMessage: "Add timezone",
  },
  titleAccessGroupRoute: {
    id: `${scope}.titleAccessGroupRoute`,
    defaultMessage: "Access group",
  },
  titleTimeZoneRoute: {
    id: `${scope}.titleTimeZoneRoute`,
    defaultMessage: "Timezone",
  },
  titleMonitorRoute: {
    id: `${scope}.titleMonitorRoute`,
    defaultMessage: "Monitoring",
  },
  titleEditSettingRoute: {
    id: `${scope}.titleEditSettingRoute`,
    defaultMessage: "Edit setting",
  },
  titleAddSettingRoute: {
    id: `${scope}.titleAddSettingRoute`,
    defaultMessage: "Add setting",
  },
  titleDeviceSettingRoute: {
    id: `${scope}.titleDeviceSettingRoute`,
    defaultMessage: "Device setting",
  },
  titleDepartmentRoute: {
    id: `${scope}.titleDepartmentRoute`,
    defaultMessage: "Department",
  },
  titleEditUserRoute: {
    id: `${scope}.titleEditUserRoute`,
    defaultMessage: "Edit user",
  },
  titleUserRoute: {
    id: `${scope}.titleUserRoute`,
    defaultMessage: "User",
  },
  titleAddUserRoute: {
    id: `${scope}.titleAddUserRoute`,
    defaultMessage: "Add user",
  },
  titleDeviceUpdasteRoute: {
    id: `${scope}.titleDeviceUpdasteRoute`,
    defaultMessage: "Device update",
  },
  titleBuildingRoute: {
    id: `${scope}.titleBuildingRoute`,
    defaultMessage: "Building",
  },
  titleMessageSettingRoute: {
    id: `${scope}.titleMessageSettingRoute`,
    defaultMessage: "Message Setting",
  },
  titleVisitReportRoute: {
    id: `${scope}.titleVisitReportRoute`,
    defaultMessage: "방문 현황",
  },
  titleEntranceVisitRoute: {
    id: `${scope}.titleEntranceVisitRoute`,
    defaultMessage: "방문신청 관리",
  },
  titleEntranceVisitApplicationRoute: {
    id: `${scope}.titleEntranceVisitApplicationRoute`,
    defaultMessage: "방문신청",
  },
  titleVisitApplicationInAdvanceRoute: {
    id: `${scope}.titleVisitApplicationInAdvanceRoute`,
    defaultMessage: "사전방문신청",
  },
  titleCentralMonitorRoute: {
    id: `${scope}.titleCentralMonitorRoute`,
    defaultMessage: "중앙 관리",
  },
  titleCompanyRoute: {
    id: `${scope}.titleCompanyRoute`,
    defaultMessage: "Company",
  },
  titleSystemAccountRoute: {
    id: `${scope}.titleSystemAccountRoute`,
    defaultMessage: "System Account",
  },
  deviceUpdateSuccess: {
    id: `${scope}.deviceUpdateSuccess`,
    defaultMessage: "Device updated successfully",
  },
  deviceUpdateFail: {
    id: `${scope}.deviceUpdateFail`,
    defaultMessage: "Device update failed",
  },
  titleDownloadMobileApp: {
    id: `${scope}.titleDownloadMobileApp`,
    defaultMessage: "Download Mobile App",
  },
  titleCanteenCorner: {
    id: `${scope}.titleCanteenCorner`,
    defaultMessage: "Canteen Corners",
  },
  titleCanteenDiscount: {
    id: `${scope}.titleCanteenDiscount`,
    defaultMessage: "Canteen Discount",
  },
  titleCanteenMealType: {
    id: `${scope}.titleCanteenMealType`,
    defaultMessage: "Canteen Meal Type",
  },
  titleCanteenMealSetting: {
    id: `${scope}.titleCanteenMealSetting`,
    defaultMessage: "Canteen Meal Settings",
  },
  titleCanteenExceptionalMeals: {
    id: `${scope}.titleCanteenExceptionalMeals`,
    defaultMessage: `Canteen Exceptional Meals`,
  },
  titleCanteenMealEventLogs: {
    id: `${scope}.titleCanteenMealEventLogs`,
    defaultMessage: `Canteen Meal Event Logs`,
  },
  titleCanteenReportSettlement: {
    id: `${scope}.titleCanteenReportSettlement`,
    defaultMessage: `Canteen Report Settlement`,
  },
  titleCanteenReportByBuilding: {
    id: `${scope}.titleCanteenReportByBuilding`,
    defaultMessage: `Canteen Report By Building`,
  },
  titleCanteenReportByCorner: {
    id: `${scope}.titleCanteenReportByCorner`,
    defaultMessage: `Canteen Report By Corner`,
  },
  titleRoleSetting: {
    id: `${scope}.titleRoleSetting`,
    defaultMessage: `Role Setting`,
  },
  titleNotifications: {
    id: `${scope}.titleNotifications`,
    defaultMessage: `Notifications`,
  },
  titleCanteenDeviceMonitoring: {
    id: `${scope}.titleCanteenDeviceMonitoring`,
    defaultMessage: `Canteen Device Monitoring`,
  },
  titleCanteenMealServiceTime: {
    id: `${scope}.titleCanteenMealServiceTime`,
    defaultMessage: "Meal Service Time",
  },
  restaurantDeviceSetting: {
    id: `${scope}.restaurantDeviceSetting`,
    defaultMessage: `Restaurant`,
  },
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: `Dashboard`,
  },
  unregisteredDevices: {
    id: `${scope}.unregisteredDevices`,
    defaultMessage: `Unregistered Devices`,
  },
  titleEmailTemplate: {
    id: `${scope}.titleEmailTemplate`,
    defaultMessage: `Email Templates`,
  },
  leaveRequest: {
    id: `${scope}.leaveRequest`,
    defaultMessage: `Leave Requests`,
  },
  leaveManagement: {
    id: `${scope}.leaveManagement`,
    defaultMessage: `Leave Management`,
  },
  titleTimeAttendanceSetting: {
    id: `${scope}.titleTimeAttendanceSetting`,
    defaultMessage: `Time Attendance Setting`,
  },
  cameras: {
    id: `${scope}.cameras`,
    defaultMessage: `Cameras`,
  },
  titleTimeAttendanceReport: {
    id: `${scope}.titleTimeAttendanceReport`,
    defaultMessage: `Time Attendance Report`,
  },
  titleProfile: {
    id: `${scope}.titleProfile`,
    defaultMessage: `Profile`,
  },
  titleVisitSettings: {
    id: `${scope}.titleVisitSettings`,
    defaultMessage: `Visit Settings`,
  },
  visitByCamera: {
    id: `${scope}.visitByCamera`,
    defaultMessage: `Add Visit By Camera`,
  },
  healthMonitoring: {
    id: `${scope}.healthMonitoring`,
    defaultMessage: `Health Monitoring`,
  },
  carMonitoring: {
    id: `${scope}.carMonitoring`,
    defaultMessage: `Car Monitoring`,
  },
  carReport: {
    id: `${scope}.carReport`,
    defaultMessage: `Car Report`,
  },
  userVehicleManagement: {
    id: `${scope}.userVehicleManagement`,
    defaultMessage: `User Vehicle Managemenet`,
  },
  unitVehicleManagement: {
    id: `${scope}.unitVehicleManagement`,
    defaultMessage: `Unit Vehicle Managemenet`,
  },
  vehicleAllocationManagement: {
    id: `${scope}.vehicleAllocationManagement`,
    defaultMessage: `Vehicle Allocation Managemenet`,
  },
  cardIssuing: {
    id: `${scope}.cardIssuing`,
    defaultMessage: `Card Issuing`,
  },
  issuingAccessCard: {
    id: `${scope}.issuingAccessCard`,
    defaultMessage: `Issuing Access Card`,
  },
  issuingVisitCard: {
    id: `${scope}.issuingVisitCard`,
    defaultMessage: `Issuing Visit Card`,
  },
  issuingTempCard: {
    id: `${scope}.issuingTempCard`,
    defaultMessage: `Issuing Temp Card`,
  },
  issuingReport: {
    id: `${scope}.issuingReport`,
    defaultMessage: `Report`,
  },
  issuingSetting: {
    id: `${scope}.issuingSetting`,
    defaultMessage: `Issuance Setting`,
  },
  userSettings: {
    id: `${scope}.userSettings`,
    defaultMessage: `User Settings`,
  },
  bookManagement: {
    id: `${scope}.bookManagement`,
    defaultMessage: `Book Management`,
  },
  bookArea: {
    id: `${scope}.bookArea`,
    defaultMessage: `Book Area`,
  },
  borrowingManagement: {
    id: `${scope}.borrowingManagement`,
    defaultMessage: `Borrowing Management`,
  },
  bookReport: {
    id: `${scope}.bookReport`,
    defaultMessage: `Book Report`,
  },
  titleMeeting: {
    id: `${scope}.titleMeeting`,
    defaultMessage: `Meeting`,
  },
  titleEditPersonRoute: {
    id: `${scope}.titleEditPersonRoute`,
    defaultMessage: `Person`,
  },
  soldierManagement: {
    id: `${scope}.soldierManagement`,
    defaultMessage: `Soldier Management`,
  },
  userVehicleInReport: {
    id: `${scope}.userVehicleInReport`,
    defaultMessage: `User Vehicle In Report`,
  },
  unitVehicleOutReport: {
    id: `${scope}.unitVehicleOutReport`,
    defaultMessage: `Unit Vehicle Out Report`,
  },
  titleFirmwareVersionRoute: {
    id: `${scope}.titleFirmwareVersionRoute`,
    defaultMessage: `Firmware Management`,
  },
  // ------
  titleEditDeviceSetting: {
    id: "demasterpro.containers.DevicePage.titleEditDeviceSetting",
    defaultMessage: "단말기 설정 변경",
  },
  cardLayout: {
    id: "demasterpro.containers.CardIssuing.cardLayout",
    defaultMessage: "카드 레이아웃",
  },
});
