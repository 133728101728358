import { errorState, loadingState, responseMsgState } from "$utils/atom";
import { LANGUAGE, URL_DOMAIN } from "$utils/constants";
import { getErrorMsg } from "$utils/libs/etcLibs";
import { LogOut } from "$utils/libs/loginLibs";
import { API_HEADER, localstoreUtilites } from "$utils/persistenceData";
import { useState } from "react";
import { useSetRecoilState } from "recoil";

const lang =
  localstoreUtilites.getLanguageFromLocalStorage() || LANGUAGE.ENGLISH;

export function useMutation(url, method = "POST") {
  const [state, setState] = useState({
    loading: false,
    data: undefined,
  });

  const setErrorState = useSetRecoilState(errorState);
  const setResponseMsgStateState = useSetRecoilState(responseMsgState);
  // global loadingbar
  const setLoadingState = useSetRecoilState(loadingState);
  function mutation(body, option = { isFile: false }) {
    const options = {
      method,
      headers: option.isFile
        ? {
            ...API_HEADER.headers,
          }
        : {
            ...API_HEADER.headers,
            "Content-Type": "application/json",
          },
      body: option.isFile ? body : JSON.stringify(body),
    };
    setLoadingState(1);
    setState((prev) => ({ ...prev, loading: true }));
    let httpUrl = url;
    const last = url.slice(-1);
    if (!url.includes("culture=")) {
      if (url.indexOf("?") !== -1) {
        if (last === "&") {
          httpUrl = url.concat(`culture=${lang}`);
        } else {
          httpUrl = url.concat(`&culture=${lang}`);
        }
      } else {
        httpUrl = url.concat(`?culture=${lang}`);
      }
    }
    fetch(`${URL_DOMAIN}${httpUrl}`, options)
      .then((res) => {
        if (!res.ok) {
          if (url.includes("/unassign-users?ok=") || res.status === 300) {
            return res.json();
          }
          if (res.status === 401) {
            if (url !== "/login" && url !== "/login-step2") {
              return LogOut();
            }
          }
          return res.text().then((text) => {
            throw new Error(text);
          });
        }
        return res.json();
      })
      .then((data) => {
        if (url === "/login" && !data.licenseVerified) {
          return window.location.assign("/verify-license-key");
        }
        if (
          data?.message &&
          data?.statusCode !== 300 &&
          data?.statusCode !== 400
        ) {
          setResponseMsgStateState(data?.message);
        }
        setState((prev) => ({ ...prev, data }));
      })
      .catch((error) => {
        const msg = getErrorMsg(error);
        setErrorState(msg);
      })
      .finally(() => {
        setState((prev) => ({ ...prev, loading: false }));
        setLoadingState(0);
      });
  }
  return [mutation, { ...state }];
}
